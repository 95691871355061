/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --radius: 2rem;

    // COLORS

    --black: hsla(0 0 0% / 1);
    --white: hsla(0 0 100% / 1);
    --white-var: 0 0 100%;
    --black-var: 0 0 0%;
    --white-navigation-bar-background--kheradmen: hsla(var(--white-var) / 0.7);
    --black-navigation-bar-foreground--kheradmen: hsl(var(--black-var));

    --primary-accent--kheradmen: 297 90 16%;
    --primary-foreground--kheradmen: var(--white-var);

    --white-body-background--kheradmen: #e0e7ff;
    --blue-body-background--kheradmen: #cffafe;

    --body-background--kheradmen: linear-gradient(
      180deg,
      var(--blue-body-background--kheradmen) 0%,
      var(--white-body-background--kheradmen) 100%
    );

    --black-footer-background--kheradmen: hsl(
      0 0 0%
    ); // black, footer background.
    --white-footer-foreground--kheradmen: hsl(0 0 100%); // white, footer texts.

    --foreground-text--kheradmen: var(--black);
  }

  .dark {
    --white-navigation-bar-background--kheradmen: hsla(var(--black-var) / 0.7);
    --black-navigation-bar-foreground--kheradmen: hsl(var(--white-var));

    --white-body-background--kheradmen: #083344;
    --blue-body-background--kheradmen: #1e1b4b;

    --body-background--kheradmen: linear-gradient(
      180deg,
      var(--blue-body-background--kheradmen) 0%,
      var(--white-body-background--kheradmen) 100%
    );

    //background: hsla(0, 0%, 0%, 0.7);
    --primary-foreground--kheradmen: var(--white-var);
    --foreground-text--kheradmen: var(--white);
  }
}

@layer utilities {
  * {
    // @apply border-border;

    // this will insert a border to all elements.
    // this is useful for debugging purposes.
    // outline: 1px solid hotpink;
  }

  .navigation-bar {
    @apply bg-white-navigation-bar-background--kheradmen text-black-navigation-bar-foreground--kheradmen;
  }

  body {
    @apply font-sans min-h-screen;
    background: var(--body-background--kheradmen);
    @apply text-foreground-text--kheradmen;

    @apply antialiased;
  }

  .body {
    z-index: -1;

    &::after {
      @apply pointer-events-none;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
      @apply bg-white bg-opacity-60 backdrop-blur-lg;

      .dark & {
        @apply bg-black bg-opacity-70  backdrop-blur-lg;
      }
    }
  }

  footer.footer {
    @apply bg-black-footer-background--kheradmen text-white-footer-foreground--kheradmen;
    z-index: 1;
  }

  button {
    @apply uppercase;
  }

  .hero-button {
    @apply transition-all duration-300 ease-in-out rounded-full uppercase;
    @apply bg-primary-accent--kheradmen text-primary-foreground--kheradmen;
  }

  .product-side {
    @apply w-full flex justify-between gap-6;
    --connection-width: 1.75rem;

    .category-button {
      position: relative;
      transition: all 0.3s ease-in-out;
    }
  }
}

// @font-face {
//   font-family: "BlackWhiteBold";
//   src: url(/assets/fonts/BlackWhiteBold.ttf);
// }

// .font-black-white-bold {
//   font-family: "BlackWhiteBold";
// }

span .underline {
  text-decoration: underline;
}

.prose pre,
.prose code,
.prose .math {
  direction: ltr;
  text-align: left;
}

// OLD CODE:
// this part will be removed.

// $basePrimary: #2b1d38;
// $baseSecondary: #6dc2b4;
// $primaryText: #c6cec3;
// $secondaryText: #1e1d26;
// $borderColor: #2b1238;
// $accentColor: lighten($baseSecondary, 30%);
// $destructiveColor: #c2002f;
// $successColor: #00a825;
// $Background: #f7f9fc;

// @layer base {
//   :root {
//     --primary: #{hue($basePrimary)} #{saturation($basePrimary)} #{lightness(
//         $basePrimary
//       )};
//     --primary-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       ) + 10};
//     --secondary: #{hue($baseSecondary)} #{saturation($baseSecondary)} #{lightness(
//         $baseSecondary
//       )};
//     --secondary-foreground: #{hue($secondaryText)} #{saturation($secondaryText)}
//       #{lightness($secondaryText)};
//     --background: 0 0% 100%;
//     --popover: 0 0% 95%;
//     --card: #{hue($Background)} #{saturation($Background)} #{lightness(
//         $Background
//       )};
//     --foreground: #{hue($secondaryText)} #{saturation($secondaryText)} #{lightness(
//         $secondaryText
//       )};
//     --popover-foreground: #{hue($secondaryText)} #{saturation($secondaryText)} #{lightness(
//         $secondaryText
//       )};
//     --card-foreground: #{hue($secondaryText)} #{saturation($secondaryText)} #{lightness(
//         $secondaryText
//       )};
//     --border: #{hue($borderColor)} #{saturation($borderColor)} #{lightness(
//         $borderColor
//       )};
//     --input: 40 13% 89%;
//     --ring: 290 91% 7% / 80%;
//     --accent: #{hue($accentColor)} #{saturation($accentColor)} #{lightness(
//         $accentColor
//       )};
//     --accent-foreground: #{hue($secondaryText)} #{saturation($secondaryText)} #{lightness(
//         $secondaryText
//       )};
//     --destructive: #{hue($destructiveColor)} #{saturation($destructiveColor)} #{lightness(
//         $destructiveColor
//       )};
//     --success: #{hue($successColor)} #{saturation($successColor)} #{lightness(
//         $successColor
//       )};
//     --destructive-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       ) + 10};
//     --success-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       ) + 10};
//     --muted: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       ) + 10};
//     --muted-foreground: 0 0 #{lightness($Background) - 40};
//     --blur: linear-gradient(
//       180deg,
//       hsla(281, 34%, 30%, 0) 0%,
//       hsla(281, 34%, 30%, 40%) 100%
//     );
//   }

//   .dark {
//     --primary: #{hue($basePrimary)} #{saturation($basePrimary)} #{lightness(
//         $basePrimary
//       ) + 20};
//     --primary-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       ) - 10};
//     --secondary: #{hue($baseSecondary)} #{saturation($baseSecondary)} #{lightness(
//         $baseSecondary
//       ) - 20};
//     --secondary-foreground: #{hue($secondaryText)} #{saturation($secondaryText)}
//       #{lightness($secondaryText) + 70};
//     --background: 240 10% 3.9%;
//     --popover: 240 10% 4%;
//     --card: 240 10% 3.9%;
//     --foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       )};
//     --popover-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       )};
//     --card-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       )};
//     --border: #{hue($borderColor)} #{saturation($borderColor)} #{lightness(
//         $borderColor
//       ) + 10};
//     --input: 240 3.7% 15.9%;
//     --ring: 240 4.9% 83.9%;
//     --accent: #{hue($accentColor)} #{saturation($accentColor)} #{lightness(
//         $accentColor
//       ) - 30};
//     --accent-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       )};
//     --destructive: #{hue($destructiveColor)} #{saturation($destructiveColor)} #{lightness(
//         $destructiveColor
//       ) - 10};
//     --success: #{hue($successColor)} #{saturation($successColor)} #{lightness(
//         $successColor
//       ) - 10};
//     --destructive-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       )};
//     --success-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       )};
//     --muted: 240 3.7% 15.9%;
//     --muted-foreground: #{hue($primaryText)} #{saturation($primaryText)} #{lightness(
//         $primaryText
//       ) - 30};
//     --blur: linear-gradient(
//       180deg,
//       hsla(240, 10%, 3.9%, 0) 0%,
//       hsla(240, 10%, 3.9%, 40%) 100%
//     );
//   }
// }

// @keyframes gradientShift {
//   0% {
//     background-position: 0 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0 50%;
//   }
// }

// @media (prefers-reduced-motion: reduce) {
//   .animate-worm {
//     animation: none;
//   }
// }

// .animate-worm {
//   stroke-dasharray: 1000;
//   stroke-dashoffset: 1000;
//   animation: dash 22s linear infinite;
// }

// @keyframes dash {
//   to {
//     stroke-dashoffset: 0;
//   }
// }
